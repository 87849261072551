import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Client} from '../../../leasing/models/leasing.model';
import { REQUEST_TYPE } from '../../../leasing/leasing.constants';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserInfoComponent implements OnInit {
  @Input() client: Client;
  @Input() requestType = REQUEST_TYPE.LEASING;
  public requestTypes = REQUEST_TYPE;

  constructor() { }

  ngOnInit() {
  }

}
