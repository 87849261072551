export const LEASING_COLUMNS = [
  "index",
  "photo",
  "name",
  "price",
  "client",
  "leaseDate",
  "leaseType",
  "status",
  "wstaffProfile",
  "store",
  "edit",
];
export const OUR_CARS_COLUMNS = [
  "index",
  "photo",
  "name",
  "client",
  "request_time",
  "price",
  "status",
  "edit",
];

export enum LEASE_TYPE {
  PERSONAL = "PERSONAL",
  BUSINESS = "BUSINESS",
}

export enum LEASE_CONTRACT_LENGTH {
  SIX = "6",
  TWELVE = "12",
  TWENTY_FOUR = "24",
  THIRTY_SIX = "36",
  FORUTY_EIGHT = "48",
}

export enum LEASE_ANNUAL_MILLEAGE {
  TWELVE = "12",
  TWENTY_FOUR = "24",
  THIRTY_SIX = "36",
  FORUTY_EIGHT = "48",
}

export enum LEASE_STATUS {
  IN_PROGRESS = "IN_PROGRESS",
  ACCEPTED = "ACCEPTED",
  EXPIRED = "EXPIRED",
  DECLINED = "DECLINED",
  REQUESTED = "REQUESTED",
  CANCELED = "CANCELED",
}

export enum BUY_CAR_STATUS {
  REQUESTED = "REQUESTED",
  SOLD = "SOLD",
  CANCELED = "CANCELED",
}

export enum REQUEST_TYPE {
  LEASING = "LEASING",
  BUY_CARS = "OUR_CARS",
  SELL_CARS = "SELL_CARS",
  LOOK_FOR_ME = "LOOK_FOR_ME",
}

export const statusArray = [
  {
    name: "Requested",
    value: "REQUESTED",
  },
  {
    name: "In progress",
    value: "IN_PROGRESS",
  },
  {
    name: "Unavailable for now",
    value: "UNAVAILABLE_FOR_NOW",
  },
  {
    name: "Please change alternative cars",
    value: "PLEASE_CHANGE_ALTERNATIVE_CARS",
  },
  {
    name: "Accepted",
    value: "ACCEPTED",
  },
  {
    name: "Declined",
    value: "DECLINED",
  },
  {
    name: "Canceled",
    value: "CANCELED",
  },
  {
    name: "Expired",
    value: "EXPIRED",
  },
  {
    name: "Upload documents",
    value: "UPLOAD_DOCUMENTS",
  },
];

export const statusArraySellcar = [
  {
    name: "Accepted",
    value: "ACCEPTED",
  },
  {
    name: "Requested",
    value: "REQUESTED",
  },
  {
    name: "In progress",
    value: "IN_PROGRESS",
  },
  {
    name: "Declined",
    value: "DECLINED",
  },
  {
    name: "Canceled",
    value: "CANCELED",
  },
];
