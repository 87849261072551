import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray'
})
export class FilterArrayPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    console.log(arguments)
    if (value.length > 0 && arguments[1] !== undefined) {
      value = value.filter(element => {
        if (typeof element === 'string') {
          if (element.toLowerCase().includes(arguments[1].toLowerCase())) {
            return true
          }
          else {
            false
          }
        }
        else {
          if (element.name.toLowerCase().includes(arguments[1].toLowerCase())) {
            return true
          }
          else {
            false
          }
        }

      })
    }
    return value
  }

}
