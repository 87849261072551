import { Component, OnInit } from '@angular/core';
import { WebsocketService } from "../../../websocket/websocket.service";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  status;

  constructor(private _webSocketService: WebsocketService) {
  }

  ngOnInit() {
    this.handleNewRequests()
  }

  handleNewRequests() {
    this._webSocketService.onMessage('/leasing-request/').subscribe(
      res => {
        if (res.type === 'ADD') {
          this.status = true;
        }
      });
  }

  onClick() {
    this.status = false;
  }
}
