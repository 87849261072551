import { mapTo } from "rxjs/operators";
import { BrandModel, Leasing } from "../../leasing/models/leasing.model";
import { CarInfoType } from "./models/car-info-type.model";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DataApiConfig } from "src/app/shared/models/data-api-config";
import { BehaviorSubject, Observable } from "rxjs";
import { Car } from "src/app/shared/models/car.model";
import { API_CONSTANTS } from "src/app/api.constants";
import { toHttpParams } from "src/app/app.utils";
import { CarBrand } from "src/app/shared/models/car-brand.model";

@Injectable({
  providedIn: "root",
})
export class CMSCarService {
  public mainPhotoName: string;
  carBrands$: BehaviorSubject<CarBrand[]>;

  constructor(private _http: HttpClient) {
    this.carBrands$ = new BehaviorSubject<CarBrand[]>(null);
  }

  public getSpecialLeaseCars() {
    return this._http.get<any>(`${API_CONSTANTS.GET_SPECIAL_CARS}/root`);
  }

  public manageCarInSpecial(putBody) {
    return this._http.put<any>(`${API_CONSTANTS.GET_SPECIAL_CARS}`, putBody);
  }

  public getCars(config: DataApiConfig): Observable<HttpResponse<Car[]>> {
    const carConfig = {
      ...config,
    };
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS}?`, {
      params: toHttpParams(carConfig),
      observe: "response",
    });
  }

  public getCarsNews() {
    return this._http.get(`${API_CONSTANTS.GET_COUNT_CARS_NEWS}`);
  }

  public getCarsForLease(
    config: DataApiConfig
  ): Observable<HttpResponse<Car[]>> {
    const carConfig = {
      ...config,
    };
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS_FOR_LEASE}?`, {
      params: toHttpParams(carConfig),
      observe: "response",
    });
  }

  public getCarsOurs(config: DataApiConfig): Observable<HttpResponse<Car[]>> {
    const carConfig = {
      ...config,
    };
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS_OUR_CARS}?`, {
      params: toHttpParams(carConfig),
      observe: "response",
    });
  }

  public getCarById(id: number): Observable<Car> {
    return this._http.get<Car>(`${API_CONSTANTS.GET_CAR}/${id}`);
  }
  public hideCarById(id: number): Observable<any> {
    return this._http.post<any>(`${API_CONSTANTS.HIDE_CAR}/${id}/true`, null);
  }

  public unhideCarById(id: number): Observable<any> {
    return this._http.post<any>(`${API_CONSTANTS.HIDE_CAR}/${id}/false`, null);
  }

  public getCarAllGeneralInfoTypes(): Observable<CarInfoType[]> {
    return this._http.get<CarInfoType[]>(
      `${API_CONSTANTS.GET_CAR_ALL_GENERAL_INFO_TYPES}`
    );
  }

  public getCarAllStandardEquipInfoTypes(): Observable<CarInfoType[]> {
    return this._http.get<CarInfoType[]>(
      `${API_CONSTANTS.GET_CAR_ALL_STANDARD_EQUIPMENT_TYPES}`
    );
  }

  public getFeatures() {
    return this._http.get<any[]>(`${API_CONSTANTS.GET_FEATURES}`);
  }

  public getCarSegments() {
    return this._http.get<any>(`${API_CONSTANTS.GET_CAR_SEGMENTS}`);
  }

  public getCarAllTechicalInfoTypes(): Observable<CarInfoType[]> {
    return this._http.get<CarInfoType[]>(
      `${API_CONSTANTS.GET_CAR_ALL_TECHNICAL_SPECIFICATION_TYPES}`
    );
  }

  public getTypes(url): Observable<string[]> {
    return this._http.get<string[]>(`${url}`);
  }

  public addCar(formData: any): Observable<any> {
    return this._http
      .post(`${API_CONSTANTS.ADD_CAR}`, formData)
      .pipe(mapTo(true));
  }

  public editCar(formData: any): Observable<any> {
    return this._http
      .put(`${API_CONSTANTS.ADD_CAR}`, formData)
      .pipe(mapTo(true));
  }

  public getHiddenCars(config: DataApiConfig): Observable<HttpResponse<Car[]>> {
    const carConfig = {
      ...config,
    };
    // TODO add params
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_HIDDEN_CARS}?`, {
      params: toHttpParams(carConfig),
      observe: "response",
    });
  }

  public getBrands(): Observable<CarBrand[]> {
    return this._http.get<any[]>(`${API_CONSTANTS.GET_BRANDS}`);
  }

  public getBrandById(brandId: number): Observable<CarBrand> {
    return this._http.get<CarBrand>(`${API_CONSTANTS.GET_BRAND}/${brandId}`);
  }

  public getBrandModels(brandId: number): Observable<BrandModel[]> {
    return this._http.get<any[]>(`${API_CONSTANTS.GET_BRANDMODELS}/${brandId}`);
  }

  public getSubmodelByModel(modelId): Observable<any[]> {
    return this._http.get<any[]>(`${API_CONSTANTS.GET_SUBMODEL}/${modelId}`);
  }

  public getModelById(modelId: number): Observable<BrandModel> {
    return this._http.get<BrandModel>(`${API_CONSTANTS.GET_MODEL}/${modelId}`);
  }

  public getColours() {
    return this._http.get<any>(`${API_CONSTANTS.GET_COLORS}`);
  }

  public getCarWheelTypes() {
    return this._http.get<any>(`${API_CONSTANTS.GET_CAR_WHEEL_TYPES}`);
  }

  public getCarTypes() {
    return this._http.get<any>(`${API_CONSTANTS.GET_CAR_TYPES}`);
  }

  public getTransmissionTypes() {
    return this._http.get<any>(`${API_CONSTANTS.GET_TRANSMISSION_TYPES}`);
  }

  public getFuelTypes() {
    return this._http.get<any>(`${API_CONSTANTS.GET_FUEL_TYPES}`);
  }

  public getAllCarsWithoutFilters() {
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS}?`, {
      observe: "response",
    });
  }

  public getHiddenCarsWithoutFilters() {
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_HIDDEN_CARS}`, {
      observe: "response",
    });
  }

  public getLeasingCarsWithoutFilters() {
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS_FOR_LEASE}?`, {
      observe: "response",
    });
  }

  public getOurCarsWithoutFilters() {
    return this._http.get<Car[]>(`${API_CONSTANTS.GET_CARS_OUR_CARS}?`, {
      observe: "response",
    });
  }

  public searchInAllCars(q: string): Observable<HttpResponse<Leasing[]>> {
    const params = new HttpParams().set("searchParameter", q);
    return this._http.get<Leasing[]>(`${API_CONSTANTS.SEARCH_CAR}`, {
      params,
      observe: "response",
    });
  }

  public searchByType(
    q: string,
    type: string
  ): Observable<HttpResponse<Leasing[]>> {
    const data = {
      name: q,
      tradeType: type,
    };
    return this._http.post<Leasing[]>(
      `${API_CONSTANTS.SEARCH_CAR_BY_TYPE}`,
      data,
      { observe: "response" }
    );
  }

  public getAllLicenses() {
    return this._http.get<any>(`${API_CONSTANTS.GET_ALL_LICENSE_PLATES}`);
  }
}
