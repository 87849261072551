import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-set-volume',
  templateUrl: './set-volume.component.html',
  styleUrls: ['./set-volume.component.scss']
})
export class SetVolumeComponent implements OnInit {
  status;

  constructor(private _notificationService: NotificationService) {
  }

  ngOnInit() {
    this.status = this._notificationService.audio;
  }

  onClick() {
    this.status = this._notificationService.toggleAudio();
  }
}
