import {Injectable, OnDestroy} from '@angular/core';
import * as SockJS from 'sockjs-client';
import {API_CONSTANTS} from '../api.constants';
import {Message, StompSubscription} from '@stomp/stompjs';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, first, switchMap} from 'rxjs/operators';
import {Client, over} from 'stompjs';
import {environment} from '../../environments/environment';

export enum SocketClientState {
  // tslint:disable-next-line:naming-convention
  ATTEMPTING, CONNECTED
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  private _client: Client;
  private _state: BehaviorSubject<SocketClientState>;


  constructor() {
    this._client = over(new SockJS(API_CONSTANTS.WEBSOCKET));
    this._state = new BehaviorSubject<SocketClientState>(SocketClientState.ATTEMPTING);
    this._client.connect({}, () => {
      this._state.next(SocketClientState.CONNECTED);
    });
    if (environment.production) {
      this._client.debug = null;
    }
  }

  static jsonHandler(message: any): any {
    return JSON.parse(message.body);
  }

  static textHandler(message: Message): string {
    return message.body;
  }

  connect(): Observable<Client> {
    return new Observable<Client>(observer => {
      this._state.pipe(filter(state => state === SocketClientState.CONNECTED)).subscribe(() => {
        observer.next(this._client);
      });
    });
  }

  ngOnDestroy() {
    this.connect().pipe(first()).subscribe(inst => inst.disconnect(null));
  }

  onMessage(topic: string, handler = WebsocketService.jsonHandler): Observable<any> {
    return this.connect().pipe(first(), switchMap(inst => {
      return new Observable<any>(observer => {
        const subscription: StompSubscription = inst.subscribe(topic, message => {
          observer.next(handler(message));
        });
        return () => inst.unsubscribe(subscription.id);
      });
    }));
  }

  onPlainMessage(topic: string): Observable<string> {
    return this.onMessage(topic, WebsocketService.textHandler);
  }

  send(topic: string, payload: any): void {
    this.connect()
      .pipe(first())
      .subscribe(inst => inst.send(topic, {}, JSON.stringify(payload)));
  }
}
