import { ActivatedRoute, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class UserResolver {
    constructor(
        private _router: Router,
        private _authService: AuthService
    ) { }
    resolve(
        route: ActivatedRoute,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> {
        return this._authService.isLoggedIn()
            ? of(null)
            : this._authService.fetchUser().pipe(
                catchError((er: HttpErrorResponse) => {
                    this._router.navigateByUrl('/auth/login');
                    return of(null);
                })
            );
    }
}