import {EventEmitter, Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {WebsocketService} from '../../websocket/websocket.service';
import {Leasing} from '../../leasing/models/leasing.model';
import {LookForMe} from '../../requests/models/look-for-me.model';

@Injectable({
  providedIn: 'root'
})
export class CrmEventHandleService {
  public newLeasingRequest: EventEmitter<Leasing>;
  public newLookForMeRequest: EventEmitter<LookForMe>;

  constructor(
    private _notificationService: NotificationService,
    private _webSocketService: WebsocketService) {
    this.newLeasingRequest = new EventEmitter<Leasing>();
    this.newLookForMeRequest = new EventEmitter<LookForMe>();
    this.handleNewRequests();
    this.handleNewLookForMeRequest();
  }

  handleNewRequests() {
    this._webSocketService.onMessage('/leasing-request/').subscribe(
      res => {

        if (res.type === 'ADD') {
          res.entity.new = true;
          this._notificationService.createActionNotification(
            'Please view new requests',
            `NEW REQUEST`,
            `/crm-requests/edit/${res.entity.id}`);
          if (res.entity.car.isForLeasing) {
            this.newLeasingRequest.emit(res.entity);
          }
        }
      });
  }
  handleNewLookForMeRequest() {
    this._webSocketService.onMessage('/look-for-me/').subscribe(
      res => {
        if (res.type === 'ADD') {
          res.entity.new = true;
          this.newLookForMeRequest.emit(res);
        }
      });
  }
}
