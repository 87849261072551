import * as RequestActions from '../actions/requests.actions';

export type Action = RequestActions.All;

const defaultState = {
  leasingRequests: [],
  buyRequests: [],
  sellcarRequests: [],
  lookForMeRequests: [],
  matRequestTablesTab: 0,
  requestStatusFilter: 'default',
}

const newState = (state, newData) => {
  return Object.assign({}, state, newData)
}

export function requestsReducer(state = defaultState, action: Action) {

  console.log(action.type, state)

  switch (action.type) {
    case RequestActions.SET_LEASING_REQUESTS:
      return newState(state, {leasingRequests: action.payload})

    case RequestActions.SET_BUY_REQUESTS:
      return newState(state, {buyRequests: action.payload})

    case RequestActions.SET_SELLCAR_REQUESTS:
      return newState(state, {sellcarRequests: action.payload})

    case RequestActions.SET_LOOKFORME_REQUESTS:
      return newState(state, {lookForMeRequests: action.payload})

    case RequestActions.SET_MAT_TAB_REQUESTS:
      return newState(state, {matRequestTablesTab: action.payload})
  
    case RequestActions.SET_REQ_STATUS_FILTER:
      return newState(state, {requestStatusFilter: action.payload})

    default: 
      return state
  } 

}