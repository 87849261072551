import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LeasingService} from '../../../leasing/leasing.service';
import {HttpEventType} from '@angular/common/http';

@Component({
  selector: 'app-leasing-digital-invoice',
  templateUrl: './leasing-digital-invoice.component.html',
  styleUrls: ['./leasing-digital-invoice.component.scss']
})
export class LeasingDigitalInvoiceComponent implements OnInit {
  @Input() digitalInvoice;
  @Input() leaseId;
  @Input() editMode = true;
  uploadProgress: number;

  constructor(private _leasingService: LeasingService, private _changeRef: ChangeDetectorRef) {
  }

  ngOnInit() {
  }

  onFileChange(event) {
    this._leasingService.uploadInvoice(this.leaseId, event).subscribe(res => {
      if (res.type === HttpEventType.UploadProgress) {
        this.uploadProgress = (res.loaded * 100) / res.total;
      }
      if (res.type === HttpEventType.Response) {
        this.uploadProgress = 100;
        this.digitalInvoice = {
          dbFile: res.body.data
        };
      }
      this._changeRef.detectChanges();
    });
  }

  deleteInvoice() {
    this.uploadProgress = 0;
    this._leasingService.deleteInvoiceById(this.leaseId).subscribe(() => {
      this.digitalInvoice = null;
      this._changeRef.detectChanges();
    });

  }
}
