import { WL_CHECKBOX_OPTIONS } from './wl-checkbox.constants';
import { Component, OnInit, Input, forwardRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';

@Component({
  selector: 'wl-checkbox',
  templateUrl: './wl-checkbox.component.html',
  styleUrls: ['./wl-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WlCheckboxComponent),
      multi: true
    }
  ]
})
export class WlCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() iconUrl: string;
  @Output() change = new EventEmitter<boolean>();
  public options = WL_CHECKBOX_OPTIONS;
  private _value: boolean;
  constructor(private _changeRef: ChangeDetectorRef) { }

  public ngOnInit() {
  }
  public onValidationError(er) {
     console.log(er);
   }

   get value(): boolean {
    return this._value;
  }

   set value(val: boolean) {
     this._value = val;
     this.onChangeCallback(val);
  }

   // Function to call when the rating changes.
   onChangeCallback = (value: boolean) => {};

  // Function to call when the input is touched (when a star is clicked).
  onTouched = (value: boolean) => {};

  // Allows Angular to update the model (rating).
  // Update the model and changes needed for the view here.
  public writeValue(value: boolean): void {
    this.value = value
  }

  // Allows Angular to register a function to call when the model (rating) changes.
  // Save the function as a property to call later here.
  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChangeCallback = fn;
  }

  // Allows Angular to register a function to call when the input has been touched.
  // Save the function as a property to call later here.
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // Allows Angular to disable the input.
  public setDisabledState(isDisabled: boolean): void {
  //  this.disabled = isDisabled;
  }

  public onCheckClick(value: boolean): void {
    this.value = value;
    this.change.next(value);

  }

}
