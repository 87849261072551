import {Injectable} from '@angular/core';
import {LanguageService} from '../services/language.service';
import {HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor {
  constructor(private _languageService: LanguageService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        language: this._languageService.userLangCode
      }
    });
    return next.handle(req);
  }
}
