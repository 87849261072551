import { NavLink } from './models/nav-link.model';
export const CRM_LINKS: NavLink[] = [
{
    label: 'HOME',
    url: '/crm-home'
},
// {
//     label: 'STORES',
//     url: '/crm-stores'
// },
{
    label: 'LEASING',
    url: '/crm-leasing'
},
{
    label: 'USERS',
    url: '/crm-users'
},
{
    label: 'W-STAFF',
    url: '/crm-wstaff'
},
{
    label: 'REQUESTS',
    url: '/crm-requests'
},
// {
//     label: 'LOG',
//     url: '/crm-log'
// },
{
    label: 'CMS',
    url: '/cms'
},

];

export const CMS_LINKS: NavLink[] = [
    {
        label: 'CMS',
        url: '/cms'
    },
    {
        label: 'CARS',
        url: '/cms/cars'
    },
    {
        label: 'NEWS',
        url: '/cms/news'
    },
];