import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { LeasingService } from '../leasing/leasing.service';

@Component({
  selector: 'app-send-notification',
  templateUrl: './send-notification.component.html',
  styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent implements OnInit {
  item: any;
  itemWithAltCars: any;
  cars: Array<any>

  constructor(
    public dialogRef: MatDialogRef<SendNotificationComponent>,
    private _leasingService: LeasingService,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.cars = this.data.cars
    console.log(this.data.item)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  deleteCarLocal(index) {
    this.cars.splice(index, 1);
  }

  sendNotification() {
    let title, message, reqBoolean;
    if (this.data.item.requestType === 'OUR_CARS') {
      title = 'Buy car request'
    }
    else if (this.data.item.requestType === 'LOOK_FOR_ME') {
      title = 'Look for me request'
    }
    else {
      title = 'Leasing request'
    }
    if (this.cars.length === 1) {
     message = 'We have attached car for you!';
    }
    else if (this.cars.length === 0) {
      message = 'We cannot offer you other cars for you!'
    }
    else {
      message = `We have ${this.cars.length} attached car for you!`
    }
    let type = 'REQUEST';
    if (this.cars.length === 0) {

    }
    let carsId = [];
    this.cars.forEach(elem => {
      carsId.push(elem.id)
    });
    this._leasingService.sendNotification(this.data.client.id, title, 
    message, carsId, type)
    .subscribe(res => {
      this.dialogRef.close(true)
    })
    if (this.data.item.requestType !== "LOOK_FOR_ME") {
      reqBoolean = true;
    }
    else {
      reqBoolean = false;
    }
    this._leasingService.putShowedCars(this.data.item.id, reqBoolean, carsId).subscribe(res => {
      console.log(res)
    })
  }
}
