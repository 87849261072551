import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusNaming'
})
export class StatusNamingPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let transformedValue = null;
    if (value === 'PLEASE_CHANGE_ALTERNATIVE_CARS') {
      transformedValue = 'Change alt. cars'
    }
    else if (value === 'IN_PROGRESS') {
      transformedValue = 'In progress'
    }
    else if (value === 'UNAVAILABLE_FOR_NOW') {
      transformedValue = 'Unavailable'
    }
    else {
      transformedValue = value.toLowerCase()
      transformedValue = capitalizeFirstLetter(transformedValue)
    }
    return transformedValue;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}