import {DEFAULT_API_ERROR} from '../../app.constants';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  audio = 'enabled';

  constructor(
    private _snackBar: MatSnackBar,
    private _toastr: ToastrService,
    private _router: Router) {
    const audio = localStorage.getItem('audio');
    if (audio) {
      this.audio = audio;
    }
  }

  public createNotification(message: string, type: string) {
    this.playAudio();
    this._snackBar.open(message, '', {
      duration: 2000,
      panelClass: [`snackbar-${type}`]
    });
  }

  public createActionNotification(message: string, title: string, link: string) {
    const toast = this._toastr.show(message, title, {}, 'wl-toast-class');
    this.playAudio();
    toast.onTap.subscribe(res => {
      if (link) {
        this._router.navigate([link]).then();
      }
      toast.toastRef.close();
    });
  }

  public createDefaultApiErrorNotification(): void {
    this._snackBar.open(DEFAULT_API_ERROR, 'error', {
      duration: 2000,
      panelClass: [`snackbar-error`]
    });
  }

  playAudio() {
    if (this.audio !== 'enabled') {
      return;
    }
    const audio = new Audio();
    audio.src = '../../../assets/audio/chime.mp3';
    audio.load();
    audio.play().then();
  }

  toggleAudio() {
    this.audio = this.audio === 'enabled' ?  'disabled' : 'enabled';
    localStorage.setItem('audio', this.audio);
    return this.audio;
  }
}
