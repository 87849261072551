import { UserResolver } from './shared/resolvers/user.resolver';
import { HeaderModule } from './header/header.module';
import { NotificationService } from './shared/services/notification.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { requestsReducer } from './store/reducers/requests.reducer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { MaterialModule } from './material.module';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { JwtModule } from '@auth0/angular-jwt';
import { APP_KEY } from './app.constants';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {TranslateLoaderFactory} from './app.translate.factory';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';
import {WebsocketModule} from './websocket/websocket.module';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GalleryComponent } from './gallery/gallery.component';
import { SendNotificationComponent } from './send-notification/send-notification.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { GallerySellcarComponent } from './gallery-sellcar/gallery-sellcar.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function tokenGetter() {
  return localStorage.getItem(`${APP_KEY}_token`);
}
@NgModule({
  declarations: [
    AppComponent,
    GalleryComponent,
    SendNotificationComponent,
    ConfirmPopupComponent,
    GallerySellcarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    HeaderModule,
    PerfectScrollbarModule,
    StoreModule.forRoot({ state: requestsReducer }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      defaultLanguage: 'en'
    }),
    CoreModule,
    ToastrModule.forRoot({
      closeButton: true,
      tapToDismiss: false,
      disableTimeOut: true
    }),
    WebsocketModule

  ],
  entryComponents: [
    GalleryComponent,
    SendNotificationComponent,
    ConfirmPopupComponent,
    GallerySellcarComponent
  ],
  providers: [
    NotificationService,
    UserResolver,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: ['en', 'nl'] }},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
