import {Pipe, PipeTransform} from '@angular/core';
import {CMSCarService} from '../../cms/cms-cars/cms-car.service';
import {CarBrand} from '../models/car-brand.model';

@Pipe({
  name: 'brandName'
})
export class BrandNamePipe implements PipeTransform {
  brands: CarBrand[];

  constructor(private _carsService: CMSCarService) {
    this.brands = this._carsService.carBrands$.getValue();
    if (!this.brands) {
      this._carsService.getBrands().subscribe(res => {
        this._carsService.carBrands$.next(res);
        this.brands = res;
      });
    }
  }

  transform(brandId: number, ...args: any[]): any {
    if (!this.brands || !brandId) {
      return brandId;
    }
    const currentBrand = this.brands.find(brand => {
      return brand.id === brandId;
    });
    if (currentBrand) {
      return currentBrand.name;
    } else {
      return brandId;
    }
  }

}
