import { environment } from "../environments/environment";

const serverURL = environment.serverUrl;
const userChartURL = `${serverURL}/api/crm/user/chart`;
export const API_CONSTANTS = {
  LOGIN_API: `${serverURL}/api/authenticate`,
  REGISTER_API: `${serverURL}/api/registration`,
  CHECK_EMAIL_EXIST: `${serverURL}/api/account/checkEmailExisting`,
  SEND_VERIFY: `${serverURL}/api/email-reset-key`,
  VERIFY_CODE: `${serverURL}/api/email-verify-reset-key`,
  SET_NEW_PASSWORD: `${serverURL}/api/email-reset-password`,

  GET_ALL_USERS_DATA: `${serverURL}/api/crm/users`,
  GET_USER: `${serverURL}/api/account`,
  GET_USER_ACIVITY: `${serverURL}/api/crm/user/activity`,
  GET_USER_BY_ID: `${serverURL}/api/crm/user`,
  GET_USER_CONTRACTS: `${serverURL}/api/contracts`,
  GET_USER_CONTRACTS_BY_ID: `${serverURL}/api/contracts/by-user-and-period`,

  GET_CAR_SEGMENTS: `${serverURL}/api/car-segment`,
  SET_PERIOD: `${serverURL}/api/crm/leasing-request/period/contract-length`,
  DELETE_USER: `${serverURL}/api/delete`,
  SEARCH_USER: `${serverURL}/api/crm/user/search`,
  GET_USERS_CHART_YEAR: `${userChartURL}/year`,
  GET_USERS_CHART_MONTH: `${userChartURL}/month`,
  GET_USERS_CHART_WEEK: `${userChartURL}/week`,
  GET_USERS_CHART_DAY: `${userChartURL}/day`,

  GET_COUNT_CARS_NEWS: `${serverURL}/api/widget/cars-and-news`,
  PUT_SHOWED_CARS_TO_BASE: `${serverURL}/api/adapter/showed-cars`,

  GET_BRANDS: `${serverURL}/api/brands`,
  GET_BRAND: `${serverURL}/api/brand`,
  GET_BRANDMODELS: `${serverURL}/api/brand/models`,
  GET_MODEL: `${serverURL}/api/brand-model`,
  GET_SUBMODEL: `${serverURL}/api/sub-model`,

  GET_CARS: `${serverURL}/api/cars`,
  GET_HIDDEN_CARS: `${serverURL}/api/hidden/car`,
  GET_CARS_VIEWS: `${serverURL}/api/widget/viewed-cars`,
  GET_CARS_FOR_LEASE: `${serverURL}/api/for-lease`,
  GET_CARS_OUR_CARS: `${serverURL}/api/for-buy`,
  GET_COLORS: `${serverURL}/api/colours/dynamic`,
  GET_CAR_WHEEL_TYPES: `${serverURL}/api/car-wheel-drive-types`,
  GET_CAR_TYPES: `${serverURL}/api/car-types`,
  GET_TRANSMISSION_TYPES: `${serverURL}/api/transmission-types`,
  GET_FUEL_TYPES: `${serverURL}/api/fuel-types`,
  GET_ALL_LICENSE_PLATES: `${serverURL}/api/all-license-plate`,
  GET_FEATURES: `${serverURL}/api/features`,
  GET_SPECIAL_CARS: `${serverURL}/api/special-option`,

  GET_CAR: `${serverURL}/api/car`,
  ADD_CAR: `${serverURL}/api/car-import`,
  DELETE_CAR: `${serverURL}/api/car`,
  HIDE_CAR: `${serverURL}/api/hidden/car`,
  GET_MY_CAR_LEASE_REQUESTS: `${serverURL}/api/leasing-request/my-requests/for-lease`,
  GET_LEASE_REQUESTS_BY_USER_ID: `${serverURL}/api/crm/leasing-request/user/`,
  GET_CAR_ALL_GENERAL_INFO_TYPES: `${serverURL}/api/crm/cars/general-info-types`,
  GET_CAR_ALL_STANDARD_EQUIPMENT_TYPES: `${serverURL}/api/crm/cars/standard-equipment-types`,
  GET_CAR_ALL_TECHNICAL_SPECIFICATION_TYPES: `${serverURL}/api/crm/cars/technical-specification-types`,
  GET_CAR_WHEEL_DRIVE_TYPES: `${serverURL}/api/car-wheel-drive-types`,
  GET_CAR_TRANSMISSION_TYPES: `${serverURL}/api/transmission-types`,
  GET_CAR_FUEL_TYPES: `${serverURL}/api/fuel-types`,
  SEARCH_CAR: `${serverURL}/api/crm/cars/search`,
  SEARCH_CAR_BY_TYPE: `${serverURL}/api/cars-search-by`,

  GET_ALTERNATIVE_CARS_BY_ID: `${serverURL}/api/crm/leasing-request/set-alternative-cars`,
  PUT_ALTERNATIVE_CARS_TO_BLACK_LIST: `${serverURL}/api/crm/leasing-request/remove-from-alternative-cars`,
  SET_REQUEST_STATUS: `${serverURL}/api/crm/leasing-request`,
  SET_SELLCAR_REQ: `${serverURL}/api/sell-car/change-status-and_staff-profile`,
  POST_NOTIFICATION_PUSH_CARS: `${serverURL}/api/crm/send-push`,
  GET_CARS_LOOK_REQ: `${serverURL}/api/look-for-me/add-alternative-cars`,
  PUT_ALTERNATIVE_CARS_TO_BLACK_LIST_LOOK: `${serverURL}/api/look-for-me/delete-alternative-cars`,
  SET_STATUS_STAFF_LOOK_FOR_ME: `${serverURL}/api`,

  POST_DATE_NOTIFICATION: `${serverURL}/api/custom-task`,

  GET_NEWS: `${serverURL}/api/news`,
  GET_NEW_POST: `${serverURL}/api/new`,
  UPDATE_POST: `${serverURL}/api/new`,
  ADD_NEWS: `${serverURL}/api/new`,
  DELETE_NEWS: `${serverURL}/api/new`,
  GET_ALL_FOR_LEASE: `${serverURL}/api/leasing-request`,
  GET_ALL_FOR_BY_REQUESTS: `${serverURL}/api/crm/leasing-request/for-buying`,
  GET_ALL_FOR_LEASE_REQUESTS: `${serverURL}/api/crm/leasing-request/for-leasing`,
  GET_ALL_LOOK_FOR_ME: `${serverURL}/api/look-for-me`,
  GET_ALL_SELL_REQUESTS: `${serverURL}/api/sell-car`,
  GET_SELL_REQUEST_BY_ID: `${serverURL}/api/sell-car`,
  GET_SELL_ASSIGN_WSTAFF: `${serverURL}/api/sell-car`,
  SET_SELL_REQUEST_STATUS: `${serverURL}/api/sell-car/change-status`,
  DELETE_SELL_REQUEST_BY_ID: `${serverURL}/api/sell-car`,
  GET_LOOK_FOR_ME_BY_ID: `${serverURL}/api/look-for-me`,
  UPDATE_LOOK_FOR_ME_STATUS: `${serverURL}/api`,
  GET_SIMILAR_CAR_BY_ID: `${serverURL}/api/get-similar`,
  GET_LEASE_BY_ID: `${serverURL}/api/crm/leasing-request`,
  UPDATE_REQUEST: `${serverURL}/api/crm/leasing-request`,
  DELETE_REQUEST: `${serverURL}/api/crm/leasing-request`,
  GET_ALL_REQUESTS_BY_IDS: `${serverURL}/api/leasing-requests/ids`,
  GET_NEW_REQUESTS: `${serverURL}/api/leasing-request/all-by-period`,
  GET_UPCOMING_MEETINGS: `${serverURL}/api/crm/leasing-request/sort?`,

  GET_USER_REQUESTS_LEASING: `${serverURL}/api/crm/user/get-my-leasing-request`,
  GET_USER_REQUESTS_BUY: `${serverURL}/api/crm/user/get-my-leasing-request`,
  GET_USER_REQUESTS_SELLCAR: `${serverURL}/api/crm/user/get-my-leasing-request`,
  GET_USER_REQUESTS_LOOKFORME: `${serverURL}/api/crm/user/get-my-leasing-request`,

  SET_USER_BLOCK_BY_ID: `${serverURL}/api/crm/user/block`,
  SET_USER_UNBLOCK_BY_ID: `${serverURL}/api/crm/user/unblock`,
  GET_USER_REQUESTS_BY_ID: `${serverURL}/api/crm/user/requests`,

  SEARCH_LEASING: `${serverURL}/api/crm/leasing-request/search`,
  SEARCH_LOOK_FOR_ME: `${serverURL}/api/look-for-me/search`,
  GET_LEASING_REQUEST_CHART_BY_YEAR: `${serverURL}/api/crm/leasing-request/chart/year`,
  GET_LEASING_REQUEST_CHART_BY_MONTH: `${serverURL}/api/crm/leasing-request/chart/month`,
  GET_LEASING_REQUEST_CHART_BY_WEEK: `${serverURL}/api/crm/leasing-request/chart/week`,
  GET_ALL_REQUEST_BY_PERIOD: `${serverURL}/api/widget/request-status`,
  GET_ALL_STATUS_BY_PERIOD: `${serverURL}/api/widget/request-all`,
  GET_PERSONAL_STATISTIC_BY_PERIOD: `${serverURL}/api/widget/request-personal`,
  GET_NEW_REQUESTS_BY_PERIOD: `${serverURL}/api/widget/new-requests`,

  GET_ALL_REGISTERED_USERS: `${serverURL}/api/users/count`,
  GET_ONLINE_USERS: `${serverURL}/api/users/active/count`,
  GET_APP_USERS: `${serverURL}/api/users/app/count`,
  GET_APP_ACTIVE_USERS: `${serverURL}/api/active/count`,
  GET_USER_WIDGET_DATA: `${serverURL}/api/widget/users`,
  GET_ALL_VISITORS: `${serverURL}/api/widget/visitors`,
  GET_VISITORS_BY_PLATFORM: `${serverURL}/api/widget/platforms`,
  GET_VISITORS_WITH_WITHOUT_LEASING: `${serverURL}/api/widget/leasing`,

  GET_PUSH_NOTIFICATIONS_HISTORY: `${serverURL}/api/crm/push`,
  GET_EMAIL_NOTIFICATIONS_HISTORY: `${serverURL}/api/crm/email`,

  GET_ALL_WSTAFF: `${serverURL}/api/w-staff/all`,
  GET_WSTAFF_BY_ID: `${serverURL}/api/w-staff`,
  ADD_NEW_WSTAFF: `${serverURL}/api/w-staff/add`,
  BLOCK_WSTAFF: `${serverURL}/api/w-staff/block`,
  UNBLOCK_WSTAFF: `${serverURL}/api/w-staff/unblock`,
  GET_WSTAFF_CONTRACTS_BY_LIST_ID: `${serverURL}/api/contracts/by-ids`,
  GET_WSTAFF_CARS_BY_LIST_ID: `${serverURL}/api/cars/ids`,
  GET_WSTAFF_NEWS_BY_LIST_ID: `${serverURL}/api/news/ids`,
  GET_WSTAFF_ACTIVITY_BY_ID: `${serverURL}/api/logs/`,

  GET_ALL_CARS_IN_LEASING: `${serverURL}/api/crm/leasing-request/cars`,
  GET_CRM_USERS: `${serverURL}/api/crm/user`,

  UPLOAD_CONTRACT_BY_ID_LOOK_FOR_ME: `${serverURL}/api/contract/upload-for-look-for-me`,
  DELELE_CONTRACT_BY_LOOK_ID: `${serverURL}/api/contracts/look-for-me`,

  UPLOAD_CONTRACT_BY_LEASE_ID: `${serverURL}/api/contract/upload/`,
  DELETE_CONTRACT_BY_ID: `${serverURL}/api/contracts/`,
  DELETE_CONTRACT_BY_LEASE_ID: `${serverURL}/api/contracts/leasing-request/`,
  UPLOAD_INVOICE_BY_LEASE_ID: `${serverURL}/api/digital-invoice/upload/`,
  DELETE_INVOICE_BY_ID: `${serverURL}/api/digital-invoice/`,
  DELETE_INVOCI_BY_LEASE_ID: `${serverURL}/api/digital-invoices/leasing-request/`,
  WEBSOCKET: `${serverURL}/socket`,

  SET_LEASING_STATUS_UNREAD: `${serverURL}/api/crm/leasing-request/`,
  SET_LOOK_STATUS_UNREAD: `${serverURL}/api/look-for-me/`,
};
