import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-leasing-file-uploader',
  templateUrl: './leasing-file-uploader.component.html',
  styleUrls: ['./leasing-file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeasingFileUploaderComponent implements OnInit {
  @Input() title: string;
  @Input() progressUpload: number;
  @Output() fileChange: EventEmitter<any>;
  filePattern = new RegExp('application/pdf');

  constructor() {
    this.fileChange = new EventEmitter<any>();
  }

  ngOnInit() {
  }
  onFileChanged(event) {
    this.fileChange.emit(event.target.files[0]);
  }
  onDragFile(event) {
    this.fileChange.emit(event[0]);
  }
}
