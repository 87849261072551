import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByValue'
})
export class FilterByValuePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let arrayToReturn = []
    if (arguments[1] !== undefined) {
      let stringToMatch = arguments[1].toLowerCase()
      for (let i = 0; i < value.length; i++) {
        let fullName = `${value[i].name} ${value[i].surname}`.toLowerCase()
        if (fullName.includes(stringToMatch)) {
          arrayToReturn.push(value[i])
        }
      }
    }
    else {
      arrayToReturn = value
    }
    return arrayToReturn;
  }

}
