import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss']
})
export class RequestStatusComponent implements OnInit {
  @Input() status: string;
  @Input() statusList: string[];
  @Output() statusChange: EventEmitter<string>;

  constructor() {
    this.statusChange = new EventEmitter<string>();
  }

  ngOnInit() {
  }

  onChange(event) {
    this.statusChange.emit(event);
  }

}
