import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfPreviewComponent implements OnInit {
  @Input() pdfUrl = 'assets/simple.pdf';

  constructor() { }

  ngOnInit() {
  }

}
