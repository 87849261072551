import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LeasingService} from '../../../leasing/leasing.service';
import {HttpEventType} from '@angular/common/http';
import { NotificationService } from '../../services/notification.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmPopupComponent } from 'src/app/confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-leasing-contract',
  templateUrl: './leasing-contract.component.html',
  styleUrls: ['./leasing-contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeasingContractComponent implements OnInit, OnDestroy {
  @Input() contract;
  @Input() leaseId;
  @Input() editMode = true;
  @Input() item;
  @Input() sourcePage;

  uploadProgress: number;

  constructor(
    private _leasingService: LeasingService,
    public _notficationService: NotificationService,
    private _changeRef: ChangeDetectorRef,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    console.log('SINGLE CONTRACT')
    console.log(this.contract)
    console.log(this.sourcePage)
  }

  onFileChange(event) {
    console.log(this.item)
    if (this.item.requestType !== 'LOOK_FOR_ME') {
      this._leasingService.uploadContract(this.leaseId, event).subscribe(res => {
        if (res.type === HttpEventType.UploadProgress) {
          this.uploadProgress = (res.loaded * 100) / res.total;
        }
        if (res.type === HttpEventType.Response) {
          res.status === 200 ? 
          this._notficationService.createNotification(`Contact successfully uploaded!`, 'green') :
          this._notficationService.createNotification(`Something went wrong!`, 'red');
          this.uploadProgress = 100;
          this.contract = {
            dbFile: res.body.data
          };
        }
        this._changeRef.detectChanges();
      });
    }
    else {
      this._leasingService.uploadContactToLookForMe(this.item.id, event).subscribe(res => {
        if (res.type === HttpEventType.UploadProgress) {
          this.uploadProgress = (res.loaded * 100) / res.total;
        }
        if (res.type === HttpEventType.Response) {
          res.status === 200 ? 
          this._notficationService.createNotification(`Contact successfully uploaded!`, 'green') :
          this._notficationService.createNotification(`Something went wrong!`, 'red');
          this.uploadProgress = 100;
          this.contract = {
            dbFile: res.body.data
          };
        }
        this._changeRef.detectChanges();
      })
    }
  }

  deleteContract() {
    this.uploadProgress = 0;
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        text: [`Do you really want to delete contact?`, `You will remove it from the server permanently`] 
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        if (this.item.requestType !== 'LOOK_FOR_ME') {
          this._leasingService.deleteContractById(this.leaseId).subscribe((res) => {
            if (res.data === true) {
              this.contract = null;
              this._changeRef.detectChanges();
              this._notficationService.createNotification(`Contact successfully deleted!`, 'green');
            }
            else {
              this._notficationService.createNotification(`Something went wrong!`, 'red');
            }
          });
        }
        else {
          this._leasingService.deleteLookForMeContract(this.leaseId).subscribe((res) => {
            if (res === true) {
              this.contract = null;
              this._changeRef.detectChanges();
              this._notficationService.createNotification(`Contact successfully deleted!`, 'green');
            }
            else {
              this._notficationService.createNotification(`Something went wrong!`, 'red');
            }
          })
        }
      }
    });


  }

  ngOnDestroy(): void {
    this._changeRef.detach();
  }
}
