import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-select-recipients',
  templateUrl: './select-recipients.component.html',
  styleUrls: ['./select-recipients.component.scss']
})
export class SelectRecipientsComponent implements OnInit {

  public markedUsers: Array<any> = []
  public filterWord: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<SelectRecipientsComponent>) { 
    this.markedUsers = this.data.checkedUsers;
  }

  ngOnInit() {
    
  }

  onNoClick(boolean) {
    if (boolean) {
      this.dialogRef.close(this.markedUsers);
    }
    else {
      this.dialogRef.close()
    }
  }

  public markUser(user) {
    let match = false
    let findedIndex = null
    this.markedUsers.forEach((markedUser, index) => {
      if (markedUser.id === user.id) {
        match = true
        findedIndex = index
      }
    })
    if (match) {
      this.markedUsers.splice(findedIndex, 1)
    }
    else {
      this.markedUsers.push(user)
    }
    return null
  }

  public checkInArray(id) {
    let match;
    for (let i = 0; i < this.markedUsers.length; i++) {
      if (match) {
        break;
      }
      this.markedUsers[i].id === id ? match = true : null
    }
    return match
  }

}
