import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'returnArrayLength'
})
export class FilterRequestByStatusPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    value = value.filter(element => {
      return element.status !== 'ACCEPTED'
    })

    console.log(value)

    return value.length
  }

}
