import { catchError, mapTo } from "rxjs/operators";
import { NotificationService } from "./../shared/services/notification.service";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { Leasing } from "./models/leasing.model";
import { API_CONSTANTS } from "../api.constants";
import { DataApiConfig } from "../shared/models/data-api-config";
import { toHttpParams } from "../app.utils";
import { LookForMe } from "../requests/models/look-for-me.model";
import { Car } from "../shared/models/car.model";

@Injectable({
  providedIn: "root",
})
export class LeasingService {
  constructor(
    private _http: HttpClient,
    private _notificationService: NotificationService
  ) {}

  public getAllLeasingRequests() {
    return this._http.get<any>(`${API_CONSTANTS.GET_ALL_FOR_LEASE_REQUESTS}?size=99999999&sort=createdDate,desc`);
  }

  public getAllBuyRequests() {
    return this._http.get<any>(`${API_CONSTANTS.GET_ALL_FOR_BY_REQUESTS}?size=99999999&sort=createdDate,desc`);
  }

  public getAllLookForMeRequests() {
    return this._http.get<any>(`${API_CONSTANTS.GET_ALL_LOOK_FOR_ME}?size=99999999&sort=createdDate,desc`);
  }

  public getAllSellcarRequests() {
    return this._http.get<any>(`${API_CONSTANTS.GET_ALL_SELL_REQUESTS}?size=99999999&sort=createdDate,desc`);
  }

  public getAllForLease(
    config: DataApiConfig,
    dataApi: string
  ): Observable<HttpResponse<any>> {
    return this._http.get<any>(`${dataApi}`, {
      params: toHttpParams(config),
      observe: "response",
    });
  }

  public getAllLookForMe(
    config: DataApiConfig
  ): Observable<HttpResponse<LookForMe[]>> {
    return this._http.get<LookForMe[]>(`${API_CONSTANTS.GET_ALL_LOOK_FOR_ME}`, {
      params: toHttpParams(config),
      observe: "response",
    });
  }

  public getLeaseById(id: number): Observable<Leasing> {
    return this._http.get<Leasing>(`${API_CONSTANTS.GET_LEASE_BY_ID}/${id}`);
  }
  public getLookForMeById(id: number): Observable<LookForMe> {
    return this._http.get<LookForMe>(
      `${API_CONSTANTS.GET_LOOK_FOR_ME_BY_ID}/${id}`
    );
  }

  public updateRequest(request: Leasing): Observable<boolean> {
    return this._http
      .post<Leasing>(`${API_CONSTANTS.UPDATE_REQUEST}/${request.id}`, request)
      .pipe(
        mapTo(true),
        catchError((er) => {
          this._notificationService.createNotification(
            "Something went wrong",
            "error"
          );
          return of(null);
        })
      );
  }

  public deleteRequest(id: number): Observable<boolean> {
    return this._http
      .delete<Leasing>(`${API_CONSTANTS.DELETE_REQUEST}/${id}`, {})
      .pipe(
        mapTo(true),
        catchError((er) => {
          this._notificationService.createNotification(
            "Something went wrong",
            "error"
          );
          return of(null);
        })
      );
  }
  public deleteLookForMe(id: number): Observable<boolean> {
    return this._http
      .delete<LookForMe>(`${API_CONSTANTS.GET_LOOK_FOR_ME_BY_ID}/${id}`, {})
      .pipe(
        mapTo(true),
        catchError((er) => {
          this._notificationService.createNotification(
            "Something went wrong",
            "error"
          );
          return of(null);
        })
      );
  }

  public onSearchLeasing(
    val: string,
    status?: string[],
    isForLease?
  ): Observable<HttpResponse<any>> {
    let params = new HttpParams().set("searchParameter", val);
    if (status) {
      status.forEach((element) => {
        params = params
          .append("status", element)
          .append("isForLeasing ", "true");
      });
    }
    if (isForLease || isForLease === false) {
      params = params.set("isForLeasing", isForLease);
    }
    return this._http.get<any>(`${API_CONSTANTS.SEARCH_LEASING}`, {
      params,
      observe: "response",
    });
  }

  public onLookFormMeSearch(
    val: string
  ): Observable<HttpResponse<LookForMe[]>> {
    return this._http.get<LookForMe[]>(
      `${API_CONSTANTS.SEARCH_LOOK_FOR_ME}?searchParameter=${val}`,
      { observe: "response" }
    );
  }

  public uploadContract(leaseRequestId: string, contractFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", contractFile, contractFile.name);
    return this._http.post(
      `${API_CONSTANTS.UPLOAD_CONTRACT_BY_LEASE_ID}${leaseRequestId}`,
      uploadData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public uploadContactToLookForMe(
    reqId: string,
    contractFile
  ): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", contractFile, contractFile.name);
    return this._http.post(
      `${API_CONSTANTS.UPLOAD_CONTRACT_BY_ID_LOOK_FOR_ME}/${reqId}`,
      uploadData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public deleteContractById(leaseId: string): Observable<any> {
    return this._http.delete(
      `${API_CONSTANTS.DELETE_CONTRACT_BY_LEASE_ID}${leaseId}`
    );
  }

  public deleteLookForMeContract(reqId) {
    return this._http.delete(
      `${API_CONSTANTS.DELELE_CONTRACT_BY_LOOK_ID}/${reqId}`
    );
  }

  public uploadInvoice(leaseRequestId: string, invoiceFile): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("file", invoiceFile, invoiceFile.name);
    return this._http.post(
      `${API_CONSTANTS.UPLOAD_INVOICE_BY_LEASE_ID}${leaseRequestId}`,
      uploadData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  public deleteInvoiceById(leaseId: string): Observable<any> {
    return this._http.delete(
      `${API_CONSTANTS.DELETE_INVOCI_BY_LEASE_ID}${leaseId}`
    );
  }

  public updateLookForMeRequestStatus(requestId: number, status: string) {
    return this._http.put(
      `${API_CONSTANTS.UPDATE_LOOK_FOR_ME_STATUS}/${requestId}/status?value=${status}`,
      null
    );
  }
  public updateRequestStatus(requestId: number, status: string) {
    return this._http.put(
      `${API_CONSTANTS.UPDATE_REQUEST}/${requestId}/status?value=${status}`,
      null
    );
  }

  public getAlternativeCars(requestId: number) {
    return this._http.put<Leasing>(
      `${API_CONSTANTS.GET_ALTERNATIVE_CARS_BY_ID}/${requestId}`,
      null
    );
  }

  public putAlternativeCarToBlackList(requestId: number, carId: number) {
    return this._http.put<Leasing>(
      `${API_CONSTANTS.PUT_ALTERNATIVE_CARS_TO_BLACK_LIST}`,
      {
        leasingRequestsId: requestId,
        carIds: [carId],
      }
    );
  }

  public putAlternativeCarToBlackListLookForMe(
    requestId: number,
    carId: number
  ) {
    console.log(JSON.stringify([carId]));
    return this._http.put<LookForMe[]>(
      `${API_CONSTANTS.PUT_ALTERNATIVE_CARS_TO_BLACK_LIST_LOOK}/${requestId}?carIds=${carId}`,
      null
    );
  }

  public setRequestStatusLeasing(
    wstaffId: string,
    requestId: number,
    status: string
  ) {
    return this._http.put<Leasing>(`${API_CONSTANTS.SET_REQUEST_STATUS}`, {
      id: requestId,
      status,
      staffId: wstaffId,
    });
  }

  public setStatusStaffLookForMe(staffId, requestId, status) {
    if (staffId === null) {
      return this._http.post<any>(
        `${API_CONSTANTS.SET_STATUS_STAFF_LOOK_FOR_ME}/${requestId}/${status}`,
        null
      );
    } else {
      return this._http.post<any>(
        `${API_CONSTANTS.SET_STATUS_STAFF_LOOK_FOR_ME}/${requestId}/${status}?staffId=${staffId}`,
        null
      );
    }
  }

  public setRequestUnreadStatus(type, requestId) {
    if (type === "LEASING") {
      return this._http.put<any>(
        `${API_CONSTANTS.SET_LEASING_STATUS_UNREAD}${requestId}/true`,
        null
      );
    } else if (type === "LOOK") {
      return this._http.put<any>(
        `${API_CONSTANTS.SET_LOOK_STATUS_UNREAD}${requestId}/true`,
        null
      );
    }
  }

  public sendNotification(
    userId: string,
    title: string,
    text: string,
    cars: Array<any>,
    type: string
  ) {
    return this._http.post(
      `${API_CONSTANTS.POST_NOTIFICATION_PUSH_CARS}?userIds=${userId}`,
      {
        type,
        title,
        text,
        data: [cars],
      }
    );
  }

  public getCarsByLookReq(reqId) {
    return this._http.get<any>(`${API_CONSTANTS.GET_CARS_LOOK_REQ}/${reqId}`);
  }

  getAvialebleCars(): Observable<Car[]> {
    return this._http.get<Car[]>(`;$;{API_CONSTANTS.GET_CARS_FOR_LEASE;}`);
  }

  public putShowedCars(id, reqType: boolean, carsIds: Array<any>) {
    let itemIds: string = carsIds.join(",");
    return this._http.put<any>(
      `${API_CONSTANTS.PUT_SHOWED_CARS_TO_BASE}/${id}?isRequest=${reqType}&carIds=${itemIds}`,
      null
    );
  }

  public setLeasingPeriod(id, periodFrom, periodTo) {
    let url = `${API_CONSTANTS.SET_PERIOD}/${id}`;
    if (periodFrom !== 0) {
      url += `?leasingPeriodFrom=${periodFrom}`;
    }
    if (periodFrom !== 0 && periodTo !== 0) {
      url += `&leasingPeriodTo=${periodTo}`;
    } else if (periodFrom === 0 && periodTo !== 0) {
      url += `?leasingPeriodTo=${periodTo}`;
    }
    return this._http.post<any>(url, null);
  }
}
