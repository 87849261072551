import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';

@Directive({
  selector: '[dragDropUploadFile]'
})
export class DragDropUploadFileDirective {
  @Output() uploadFiles: EventEmitter<any[]>;
  @Input() filesType: RegExp;


  constructor() {
    this.uploadFiles = new EventEmitter();
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const files: any[] = [];
    Object.keys(evt.dataTransfer.files).forEach((key) => {
      const file = evt.dataTransfer.files[key];
      if (this.filesType) {
        if (file.type.match(this.filesType)) {
          files.push(file);
        }
      } else {
        files.push(file);
      }
    });


    if (files.length > 0) {
      this.uploadFiles.emit(files);
    }
  }


}
