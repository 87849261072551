import {WlCheckboxModule} from './components/wl-checkbox/wl-checkbox.module';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {MaterialModule} from '../material.module';
import {FilePickerModule} from 'ngx-awesome-uploader';
import {MatFabMenuModule} from '@angular-material-extensions/fab-menu';
import {ProgressSpinnerModule} from './components/progress-spinner/progress-spinner.module';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {PdfPreviewComponent} from './components/ui/pdf-preview/pdf-preview.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {DragDropUploadFileDirective} from './directives/drag-drop-upload-file.directive';
import {TranslateModule} from '@ngx-translate/core';
import {ClientInfoRequestsComponent} from './components/client-info-requests/client-info-requests.component';
import {LeasingContractComponent} from './components/leasing-contract/leasing-contract.component';
import {LeasingDigitalInvoiceComponent} from './components/leasing-digital-invoice/leasing-digital-invoice.component';
import {LeasingFileUploaderComponent} from './components/leasing-file-uploader/leasing-file-uploader.component';
import {BackButtonComponent} from './components/back-button/back-button.component';
import {BrandNamePipe} from './pipes/brand-name.pipe';
import {RequestStatusComponent} from './components/request-status/request-status.component';
import {LayoutComponent} from './components/layout/layout.component';
import {GridsterModule} from 'angular-gridster2';
import { LayoutItemDirective } from './directives/layout-item.directive';
import { SetVolumeComponent } from './components/set-volume/set-volume.component';
import {RouterModule} from '@angular/router';
import {NotificationsComponent} from './components/notifications/notifications.component';
import { LicensePlatePipe } from './pipes/license-plate.pipe';
import { AfterValueChangedDirective } from './directives/after-value-changed.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { StatusNamingPipe } from './pipes/status-naming.pipe';
import { DeleteStatusPipe } from './pipes/delete-status.pipe';
import { SelectRecipientsComponent } from './components/select-recipients/select-recipients.component';
import { FilterByValuePipe } from './pipes/filter-by-value.pipe';
import { FilterArrayPipe } from './pipes/filter-array.pipe';
import { FilterRequestByStatusPipe } from './pipes/filter-request-by-status.pipe';

@NgModule({
  imports: [
    MaterialModule,
    FormsModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FilePickerModule,
    MatFabMenuModule,
    WlCheckboxModule,
    ProgressSpinnerModule,
    NgxMatSelectSearchModule,
    PdfViewerModule,
    TranslateModule,
    GridsterModule,
    RouterModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    FilePickerModule,
    MatFabMenuModule,
    WlCheckboxModule,
    ProgressSpinnerModule,
    NgxMatSelectSearchModule,
    UserInfoComponent,
    PdfPreviewComponent,
    DragDropUploadFileDirective,
    ClientInfoRequestsComponent,
    LeasingContractComponent,
    LeasingDigitalInvoiceComponent,
    BackButtonComponent,
    BrandNamePipe,
    RequestStatusComponent,
    LayoutComponent,
    GridsterModule,
    LayoutItemDirective,
    AfterValueChangedDirective,
    SetVolumeComponent,
    NotificationsComponent,
    LicensePlatePipe,
    LoadingSpinnerComponent,
    StatusNamingPipe,
    DeleteStatusPipe,
    SelectRecipientsComponent,
    FilterByValuePipe,
    FilterArrayPipe,
    FilterRequestByStatusPipe,
  ],
  declarations: [
    UserInfoComponent,
    PdfPreviewComponent,
    DragDropUploadFileDirective,
    ClientInfoRequestsComponent,
    LeasingContractComponent,
    LeasingDigitalInvoiceComponent,
    LeasingFileUploaderComponent,
    BackButtonComponent,
    BrandNamePipe,
    RequestStatusComponent,
    LayoutComponent,
    LayoutItemDirective,
    AfterValueChangedDirective,
    SetVolumeComponent,
    NotificationsComponent,
    LicensePlatePipe,
    LoadingSpinnerComponent,
    StatusNamingPipe,
    DeleteStatusPipe,
    SelectRecipientsComponent,
    FilterByValuePipe,
    FilterArrayPipe,
    FilterRequestByStatusPipe,
  ],
  entryComponents: [
    SelectRecipientsComponent
  ]
})
export class SharedModule { }
