import { Action } from '@ngrx/store';

export const SET_LEASING_REQUESTS = '[Request] SetLeasingRequests';
export const SET_BUY_REQUESTS = '[Request] SetBuyRequests';
export const SET_SELLCAR_REQUESTS = '[Request] SetSellcarRequests';
export const SET_LOOKFORME_REQUESTS = '[Request] SetLookformeRequests';
export const SET_MAT_TAB_REQUESTS = '[Request] matRequestTablesTab';
export const SET_REQ_STATUS_FILTER = '[Request] '

export class SetLeasingRequests implements Action {
    readonly type = SET_LEASING_REQUESTS;

    constructor(public payload) {}
}

export class SetBuyRequests implements Action {
    readonly type = SET_BUY_REQUESTS;

    constructor(public payload) {}
}

export class SetSellcarRequests implements Action {
    readonly type = SET_SELLCAR_REQUESTS;

    constructor(public payload) {}
}

export class SetLookformeRequests implements Action {
    readonly type = SET_LOOKFORME_REQUESTS;

    constructor(public payload) {}
}

export class matRequestTablesTab implements Action {
    readonly type = SET_MAT_TAB_REQUESTS;

    constructor(public payload) {}
}

export class setRequestStatus implements Action {
    readonly type = SET_REQ_STATUS_FILTER;

    constructor(public payload) {}
}

export type All = SetLeasingRequests | 
                    SetBuyRequests | SetSellcarRequests | 
                    SetLookformeRequests | 
                    matRequestTablesTab |
                    setRequestStatus