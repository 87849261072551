import * as moment from 'moment';
import {HttpParams} from '@angular/common/http';

export const MOMENT_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      monthYearLabel: 'MMM YYYY',
      // See DateFormats for other required formats.
    },
  };

export function defaultDateFormat(date: string): string {
    if (!date) { return; }
    return moment(date).format('DD / MM / YYYY');
}

export function convertToTimeStamp(date: string | Date) {
  if (!date) {
    return;
  }
  return  moment(date).format('X');
}

export function dateToMonth(date: string): string {
  if (!date) {
    return;
  }
  return moment(date).format('MMM');
}

export function dateToDay(date: string): string {
    if (!date) { return; }
    return moment(date).format('DD');
}

export function dateToWeekDay(date: string): string {
    if (!date) { return; }
    return moment(date).format('MMM DD');
}

export function dateToHour(date: string): string {
    if (!date) { return; }
    return moment(date).format('LT');
}

export function getPrimaryColor(): string {
    return getComputedStyle(document.documentElement).getPropertyValue('--primary-default');
}

export function getAccentColor(): string {
    return getComputedStyle(document.documentElement).getPropertyValue('--secondary-default');
}

export function removeEmptyObjectFields(obj): Object {
    return JSON.parse(JSON.stringify(obj));
}

  /**
 * Convert Object to HttpParams
 * @param {Object} obj
 * @returns {HttpParams}
 */
export function toHttpParams(obj: Object): HttpParams {
    if (!obj) {return;}
    const newObject = removeEmptyObjectFields(obj);
    return Object.getOwnPropertyNames(newObject)
      .reduce((p, key) => p.set(key, newObject[key]), new HttpParams());
  }

  export function REMOVE_ITEMS_WITH_SPLICE(items, index: number) {
    items.splice(index, 1);
  }
