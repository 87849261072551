import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'licensePlate'
})
export class LicensePlatePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    return value[0] + ' - ' + value[1] + value[2] + value[3] + ' - ' + value[4] + value.substring(5);
  }

}
