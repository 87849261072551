import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-client-info-requests',
  templateUrl: './client-info-requests.component.html',
  styleUrls: ['./client-info-requests.component.scss']
})
export class ClientInfoRequestsComponent implements OnInit {
  @Input() item: any;
  @Input() client: any;
  constructor() { }

  ngOnInit() {

  }
}
