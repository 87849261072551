import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {LanguageService} from './services/language.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {LanguageInterceptor} from './interceptors/language.interceptor';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    LanguageService,
    {provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true}
  ]
})
export class CoreModule { }
