import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WlCheckboxComponent } from './wl-checkbox.component';



@NgModule({
  declarations: [WlCheckboxComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [WlCheckboxComponent]
})
export class WlCheckboxModule { }
