import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {UserResolver} from './shared/resolvers/user.resolver';


const routes: Routes = [
  {path: '', redirectTo: 'crm-home', pathMatch: 'full'},
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: 'crm-home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), resolve: [UserResolver]},
  {path: 'crm-leasing', loadChildren: () => import('./leasing/leasing.module').then(m => m.LeasingModule), resolve: [UserResolver]},
  {path: 'crm-requests', loadChildren: () => import('./requests/requests.module').then(m => m.RequestsModule), resolve: [UserResolver]},
  {path: 'crm-wstaff', loadChildren: () => import('./wstaff/wstaff.module').then(m => m.WstaffModule), resolve: [UserResolver]},
  {
    path: 'crm-most-viewed-cars',
    loadChildren: () => import('./most-viewed-cars/most-viewed-cars.module').then(m => m.MostViewedCarsModule),
    resolve: [UserResolver]
  },
  {
    path: 'crm-most-viewed-news',
    loadChildren: () => import('./most-viewed-news/most-viewed-news.module').then(m => m.MostViewedNewsModule),
    resolve: [UserResolver]
  },
  {path: 'crm-users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), resolve: [UserResolver]},
  {path: 'cms', loadChildren: () => import('./cms/cms.module').then(m => m.CMSModule), resolve: [UserResolver]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
