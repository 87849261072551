import {ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[layoutItem]'
})
export class LayoutItemDirective  implements OnChanges {
  @Input() componentRef: string;
  @Input() components: any;

  component: ComponentRef<any>;

  constructor(
    private _container: ViewContainerRef,
    private _resolver: ComponentFactoryResolver
  ) {
  }

  ngOnChanges(): void {
    const component = this.components[this.componentRef];

    if (component) {
      const factory = this._resolver.resolveComponentFactory<any>(component);
      this.component = this._container.createComponent(factory);
    }

  }


}
