import {NavLink} from './models/nav-link.model';
import {CMS_LINKS, CRM_LINKS} from './header.constants';
import {takeUntil} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {AuthUser} from '../auth/models/auth-user.model';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {LanguageService} from '../core/services/language.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'w-lease-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  public navLinks: NavLink[];
  public headerMode: 'CMS' | 'CRM';
  public user$: Observable<AuthUser>;
  private _onDestroy$ = new Subject<void>();
  public showToggle: Boolean = true;
  selectedLang: string;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _changeRef: ChangeDetectorRef,
    private _languageService: LanguageService,
    private _translateService: TranslateService
  ) { }

  ngOnInit() {
    this.user$ = this._getUser();
    this._assignHeaderLinks(location.href);
    this._listenToRouter();
    this.selectedLang = this._languageService.userLangCode;
    this._translateService.use(this.selectedLang);

    console.log('header on INIT')
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
  }

  public onLogout(): void {
    this._authService.logout();
  }
  private _getUser(): Observable<AuthUser> {
    return this._authService.getUser();
  }

  private _listenToRouter(): void {
    this._router.events
    .pipe(
      takeUntil(this._onDestroy$)
    )
    .subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._assignHeaderLinks(location.href);
      }
    });
  }

  private _assignHeaderLinks(url: string): void {
    this.headerMode = url.includes('crm') ? 'CRM' : 'CMS';
    this.navLinks = url.includes('crm') ? CRM_LINKS : CMS_LINKS;
    this._changeRef.detectChanges();
  }

  setLang(langCode: string) {
    this._languageService.setLang(langCode);
    this._translateService.use(langCode);
  }
}
