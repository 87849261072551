import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import { AuthService } from './auth/auth.service'
import { CrmEventHandleService } from './shared/services/crm-event-handle.service'
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router'

@Component({
  selector: 'w-lease-crm-cms',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  public showHeader: Boolean = true;

  constructor(
    public translate: TranslateService,
    private _authService: AuthService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _router: Router,
    private _eventService: CrmEventHandleService) {
    this.matIconRegistry.addSvgIcon(
      'handshake-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/handshake.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'download-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/download.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'pdf-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/pdf.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'filter-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/filter.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'trash-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/trash.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'clear-custom',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/material-custom-icons/clear.svg')
    )

  }

  ngOnInit(): void {
    this._tryAutoLogin();
    const language = window.navigator['userLanguage'] || window.navigator.language;
    const appLanguage = language.split('-')[0] === 'nl' ? 'nl' : 'en';
    this.useLanguage('en');

    this._router.events.subscribe(value => {
      if (value instanceof NavigationStart) {
        if (value.url.includes('/cms/cars/add-edit') || value.url.includes('/cms/news/add-edit')) {
          this.showHeader = false
        }
        else {
          this.showHeader = true
        }
      }
    })
  }

  useLanguage(language: string) {
    this.translate.setDefaultLang(language);
    return this.translate.use(language);
  }

  private _tryAutoLogin(): void {
    this._authService.tryAutoLogin();
  }
}

