import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {AuthUser} from './models/auth-user.model';
import {AuthRegister} from './models/auth-register';
import {API_CONSTANTS} from './../api.constants';
import {AuthLogin} from './models/auth-login.model';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {Observable, of, ReplaySubject} from 'rxjs';
import {NotificationService} from '../shared/services/notification.service';
import {AuthResponse} from './models/auth.response';
import {APP_KEY, DEFAULT_API_ERROR} from '../app.constants';
import {USER_ROLES} from './auth.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _token: string;
  private _isLoggedIn: boolean;
  private _user$: ReplaySubject<AuthUser> = new ReplaySubject<AuthUser>(100);

  constructor(
    private _http: HttpClient,
    private _notificationService: NotificationService,
    private _router: Router,
    private _jwtHelper: JwtHelperService
    ) { }

  public login(loginObject: AuthLogin): Observable<AuthResponse> {
    const { login, password, rememberMe } = loginObject;
    const isEmail = login.includes('@');
    const type = isEmail ? 'email' : 'phone';
    let params = new HttpParams()
      .set(type, login)
      .set('password', password)
      .set('remember-me', rememberMe.toString());
    return this._http.post<AuthResponse>(`${API_CONSTANTS.LOGIN_API}/${type}`, {}, { params: params })
      .pipe(
        take(1),
        tap((res) => this._setToken(res)),
        catchError(er => {
          this._notificationService.createNotification('Wrong creditentials', 'error');
          return of(null)
        })
      );
  }

  public register(authObject: AuthRegister): Observable<AuthResponse> {
    return this.checkEmailExists(authObject.email)
      .pipe(
        switchMap(res => {
          return this._http.post<AuthResponse>(`${API_CONSTANTS.REGISTER_API}`, authObject)
        }),
        tap((res) => this._setToken(res)),
        catchError(er => {
          this._notificationService.createNotification(DEFAULT_API_ERROR, 'error');
          return of(null)
        })
      )
  }

  public logout(): void {
    this._isLoggedIn = false;
    this._token = null;
    this._user$.next(null);
    localStorage.removeItem(`${APP_KEY}_token`);
    this._router.navigateByUrl('/auth/login');
    console.log('logout')
  }


  public checkEmailExists(email: string) {
    return this._http.get(`${API_CONSTANTS.CHECK_EMAIL_EXIST}?email=${email}`)
  }

  public getToken(): string {
    return this._token;
  }

  public fetchUser(): Observable<AuthUser> {
    return this._http.get<AuthUser>(API_CONSTANTS.GET_USER)
      .pipe(
        take(1),
        tap(res => console.log(res)),
        tap((res) => {
          res.roles = this._getUserRoleFromToken(this._token);
          const {roles} = res;
          res.isOwner = roles.includes(USER_ROLES.ROLE_OWNER);
          this._user$.next(res);
        })
      );
  }

  public reuseOldToken(token: string): void {
    this._token = token;
  }

  public getUser(): Observable<AuthUser> {
    return this._user$.asObservable();
  }

  public isLoggedIn(): boolean {
    return this._isLoggedIn;
  }
  public tryAutoLogin(): void {
    console.log('try auto login');
    const token = localStorage.getItem(`${APP_KEY}_token`);
    const isTokenExpired = this._jwtHelper.isTokenExpired(token);
    if (isTokenExpired) {
      localStorage.removeItem(`${APP_KEY}_token`);
      this._router.navigateByUrl('/auth/login');
    } else {
      this.reuseOldToken(token);
    }
  }

  public sendEmailCode(email) {
    return this._http.post(`${API_CONSTANTS.SEND_VERIFY}?email=${email}`, null)
  }

  public verifyCode(email, code) {
    return this._http.post(`${API_CONSTANTS.VERIFY_CODE}?email=${email}&key=${code}`, null)
  }

  public setNewPassword(token, newPassword) {
    return this._http.post(`${API_CONSTANTS.SET_NEW_PASSWORD}?token=${token}&newPassword=${newPassword}`, null)
  }

  private _setToken(res: AuthResponse): void {
    this._token = res.data;
    localStorage.setItem(`${APP_KEY}_token`, res.data);
  }

  private _getUserRoleFromToken(token): string[] {
    const decodeToken = this._jwtHelper.decodeToken(token);
    return decodeToken.auth.split(',');
  }

}
