import {Injectable} from '@angular/core';

@Injectable()
export class LanguageService {
  userLangCode = 'en';

  constructor() {
    const currentLang = localStorage.getItem('lang');
    if (currentLang) {
     this.userLangCode = currentLang;
    }
  }
  setLang(langCode: string) {
    localStorage.setItem('lang', langCode);
    this.userLangCode = langCode;
  }

}
